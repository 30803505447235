const resources ={
    cardL1: {
      title: 'Responsive',
      text:'A design adapted for all your screens in order to offer a quality service on all devices.'
    },
    cardL2: {
      title: 'User friendly',
      text:'Very easy to learn and to be understood by all users in order to easily meet your customers\' expectations.'
    },
    cardL3: {
      title: 'Latest technologies',
      text:'The latest technologies put forward in order to offer you an exceptional service to the taste of the day.'
    },
    lTextClass: 'col-12 col-md-12 col-lg-10 text-center text-md-end  order-2 order-md-2  order-lg-1'
      ,
    lImageClass: 'col-12 col-md-12 col-12 col-lg-2 order-1 order-md-1 order-lg-2 d-flex justify-content-center align-items-center'
    ,
    cardR1: {
      title: 'Clean code',
      text:'A coding developed with love by programmers who are real experts in the field of digital solutions.'
    },
    cardR2: {
      title: 'Promote your online presence',
      text:'Increase your audience and your visibility with this service to be visible anywhere in the world.'
    },
    cardR3: {
      title: 'Made With Love',
      text:'Our programmers are passionate about providing quality service to offer you the best digital solution.'
    },
    rTextClass: 'col-12 col-md-12 col-lg-10 text-center text-md-start  order-2 order-md-2  order-lg-2' 
      ,
    rImageClass: 'col-12 col-md-12 col-12 col-lg-2 order-1 order-md-1 order-lg-1 d-flex justify-content-center align-items-center'
}





export default resources;