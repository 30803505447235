const partconfig =
{
  fullScreen: {
    enable: false,
    zIndex: -1
  },

  fpsLimit: 80,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      
      
        /* all other options */
    
      onHover: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      push: {
        quantity: 0,
      },
      repulse: {
        distance: 35,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: "#9d9d9d",
    },
    links: {
      color: "#c6c6c6",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 2,
      straight: true,
    },
    number: {
      density: {
        enable: false,
        area: 400,
      },
      value: 40,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  preset: "triangles",
  style: {},
  detectRetina: true,
}

export default partconfig;