import '../App.css';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Features from '../components/Features';
import Presence from '../components/Presence';
import Contact from '../components/contact';
import Footer from '../components/Footer';


function Homepage() {
  return (
   <>
    <main>
      <NavBar />
      <Header/>
      <Presence/>
      <Features/>
      <Contact/>
      <Footer/>
    </main></>
  );
}

export default Homepage;
