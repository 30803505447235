import React from 'react';
export default function Loggedin (){
  
    
     
   return (
   <>
      <div className="row">
        <div className='col-3'>
        <h1 className="text-center">Welcome Talkeez guest</h1>
        <h2 className="text-center">{localStorage.getItem("key")}</h2>
        </div>
        <div className='col-9'>
        <div className="loginForm d-flex justify-content-center mt-3 w-25 mx-auto">
        <h1>Welcome to talkeez</h1></div>

        </div>
      </div>
      
        
        
      </>
    );
  
  
}