//import react from 'react';
import ParticlesView from './Particles';
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Iphone from '../images/iphone.png'
import Ipad from '../images/ipad.png'
import Lcd from '../images/lcd.png'




function Header(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
    <div className="header container-fluid">
        <ParticlesView/>
        <div className="row header-row">
            <div className="col-12 col-md-5 headerText d-flex justify-content-center p-5">
                <h1>Professional designers to take your business to the next level</h1>
                <h2>amazing responsive design</h2>
            </div>
            <div className="d-none d-sm-none d-md-block col-md-7 d-flex headerimages">
                    <img data-aos="fade-left" data-aos-delay="300" data-aos-duration='1200' className="iphoneimg img-fluid" src={Iphone} alt="Iphone device"/>
                    <img data-aos="fade-left" data-aos-delay="1500" data-aos-duration='1200' className="ipadimg img-fluid" src={Ipad} alt="Ipad device"/>
                    <img data-aos="fade-left" data-aos-delay="2700" data-aos-duration='1200' className="lcdimg img-fluid" src={Lcd} alt="LCD device"/>
            </div>
        </div>

    </div>
       

)}


export default Header;