//import react from 'react';
import React, { useEffect } from "react";
//import AOS from "aos";
import "aos/dist/aos.css";
import FeatureCard from './FeaturesCard';
import ImgFeature from '../images/featureImg.png';
import Heart from '../images/heart.svg';
import resources from './resources';

function Features(){
    return(
        <>
            <section className="container-fluid features justify-content-center">
                    <h1 className='text-center'>Features</h1>
                    <h2 className='text-center'>a lot of amazing & cool features</h2>
            </section>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-around '>
                            <FeatureCard aniType="fade-right" aniDelay="300" aniDuration="1200" title={resources.cardL1.title} text={resources.cardL1.text} textColClass={resources.lTextClass} 
                            icoColClass={resources.lImageClass}  image={Heart} imageAlt='heart desc'/>

                            <FeatureCard aniType="fade-right" aniDelay="400" aniDuration="1200" title={resources.cardL2.title} text={resources.cardL2.text} textColClass={resources.lTextClass} 
                            icoColClass={resources.lImageClass}  image={Heart} imageAlt='heart desc'/>
                            
                            <FeatureCard aniType="fade-right" aniDelay="500" aniDuration="1200" title={resources.cardL3.title} text={resources.cardL3.text} textColClass={resources.lTextClass} 
                            icoColClass={resources.lImageClass}  image={Heart} imageAlt='heart desc'/>

                    </div>

                
                     <div className='d-none d-sm-none d-md-flex col-md-4 justify-content-center'>
                        <img data-aos="fade-up" data-aos-delay="100" data-aos-duration='1200' className="img-fluid featimage" src={ImgFeature} alt=""/>

                     </div>

                
                     <div className='col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-around'>
                           
                            <FeatureCard aniType="fade-left" aniDelay="300" aniDuration="1200" title={resources.cardR1.title} text={resources.cardR1.text} textColClass={resources.rTextClass} 
                            icoColClass={resources.rImageClass}  image={Heart} imageAlt='heart desc'/>

                            <FeatureCard aniType="fade-left" aniDelay="400" aniDuration="1200" title={resources.cardR2.title} text={resources.cardR2.text} textColClass={resources.rTextClass} 
                            icoColClass={resources.rImageClass}  image={Heart} imageAlt='heart desc'/>
                            
                            <FeatureCard aniType="fade-left" aniDelay="500" aniDuration="1200" title={resources.cardR3.title} text={resources.cardR3.text} textColClass={resources.rTextClass} 
                            icoColClass={resources.rImageClass}  image={Heart} imageAlt='heart desc'/>




                    </div>

                </div>
            
        
        
        </>


    )
}

export default Features;