import React from 'react';
//import ReactDOM  from 'react';
function FeatureCard(props){

return(
    <div data-aos={props.aniType} data-aos-delay={props.aniDelay} data-aos-duration={props.aniDuration} className='row'>
                                <div className={props.textColClass}>
                                    <h3>{props.title}</h3>
                                    <p className="pl-5"> {props.text}</p>
                                </div>
                                <div className={props.icoColClass}>
                                    <div className='featIcon'>
                                    <img src={props.image} alt={props.imageAlt}/>

                                    </div>

                                </div>
                            </div>
)

}

export default FeatureCard;