import './App.css';
/*import NavBar from './components/NavBar';
import Header from './components/Header';
import Features from './components/Features';
import Presence from './components/Presence';
import Contact from './components/contact';
import Footer from './components/Footer';*/
import Homepage from './Pages/Homepage';
import Survey from './Pages/Survey';
import { Routes, Route } from "react-router-dom"
import Loggedin from './Pages/Loggedin';


function App() {
  return (
   
    <Routes>
          <Route exact path='/' element={<Homepage/>}/>
          <Route path='/survey' element={<Survey/>}/>
          <Route path='/loggedin' element={<Loggedin/>}/>
    </Routes>
   /*<>
    <main>
      <NavBar />
      <Header/>
      <Presence/>
      <Features/>
      <Contact/>
      <Footer/>
    </main></>*/
  );
}

export default App;
