//import react from 'react';
import React, { useEffect } from "react";
//import AOS from "aos";
import "aos/dist/aos.css";
//import FeatureCard from './FeaturesCard';
//import ImgFeature from '../images/featureImg.png';
import Website from '../images/website.svg';
import Appstore from '../images/appstore.svg';
import Googleplay from '../images/googleplay.png';
import resources from './resources';
import PresCard from './PresCard';


function Presence(){
    return(
        <>
            <section className="container-fluid features justify-content-center">
                    <h1 className='text-center'>One place</h1>
                    <h2 className='text-center'>all what you need for your online presence</h2>
                    <div className='row pt-4'>
                            <div className='col-6 offset-3'>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-4 text-center'>
                                        <a className='btn btn-secondary' href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#99ccff" className="bi bi-mouse2" viewBox="0 0 16 16">
                                        <path d="M3 5.188C3 2.341 5.22 0 8 0s5 2.342 5 5.188v5.625C13 13.658 10.78 16 8 16s-5-2.342-5-5.188V5.189zm4.5-4.155C5.541 1.289 4 3.035 4 5.188V5.5h3.5V1.033zm1 0V5.5H12v-.313c0-2.152-1.541-3.898-3.5-4.154zM12 6.5H4v4.313C4 13.145 5.81 15 8 15s4-1.855 4-4.188V6.5z"/>
                                        </svg>  <b>Sign Up Now</b></a>
                                    </div>
                                    <div className='col-md-12 col-lg-8'>
                                        <p className=" mx-auto"> You're few clicks away from customizing and publishing your online presence with the help of our amazing modern templates. </p>
                                        <p className=" mx-auto">Or request a quote today and get it designed by a Pro!</p>
                                    </div>
                                </div>
                            </div>

                    </div>
                    
            </section>
                <div className="container">
                    <div className='row gx-3'>
                    
                        
                        <PresCard aniType="fade-down" aniDelay="200" aniDuration="800" image={Website} title="WEBSITE" text="Choose a responsive website who can be visible on all the différents screens."/>
                        <PresCard aniType="fade-down" aniDelay="350" aniDuration="800" image={Appstore} title="IOS APP" text="An application available on all IOS devices. What are you waiting for to create yours?"/>
                        <PresCard aniType="fade-down" aniDelay="450" aniDuration="800" image={Googleplay} title="ANDROID APP" text="An application available on all Android devices. What are you waiting for to create yours?"/>
                        
                        
                    </div>
                </div>
            
        
        
        </>


    )
}

export default Presence;