
import React, { Component } from "react";

import GMap from "./GMap";


export default class Contact extends Component {
    
     state={
        fullName:'',
        email:'',
        subject:'',
        message:'',
        sent:false
       }
     handleChange=input => (e) =>{
        this.setState({
         [input]:e.target.value
        })
       }
      

      formSubmit=(e)=>{
        var params = "fullName="+this.state.fullName+"&email="+this.state.email+"&subject="+this.state.subject+"&message="+this.state.message;
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load',()=>{
            //server response
            console.log(xhr.responseText)

        })
        
        xhr.open('POST','https://imonline.ca/php/reqquote.php',true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.send(params);
        this.resetForm();
        e.preventDefault();
        
        
      }
    resetForm=()=>{
        this.setState({
            fullName:'',
            email:'',
            subject:'',
            message:''
        })
        setTimeout(()=>{
            this.setState({
                sent:false,
            })
        },3000)
    }



    /* const [sent, setSent] = useState(false)
    const [text, setText] = useState("")
  
    const handleSend = () => {
        
        setSent(true)
        try {
            
             axios.post("http://localhost:3000/sendmail",{
                text
            })
            
        } catch (error) {
            console.log(error)
        }
    }*/
    render(){
        return(
            <div className="contact row">
                <h1 id="contactus">Contact Us</h1>
                <h2>we love to collaborate with our users</h2>
                <div data-aos="fade-right" data-aos-delay="500" data-aos-duration='1200' className="col-md-3 col-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 text-sm-center text-md-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
                            </svg>

                        </div>
                        <div className="col-md-9 col-sm-12 text-center text-md-start text-sm-center">
                            <h3>Address</h3>
                            <p>Laval, Québec, Canada</p>
                            <p>info@ImOnline.ca</p>
                            <p>(514) 812-8122</p>
                        </div>
                        <div className="col-md-3 col-sm-12 text-sm-center text-md-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                            </svg>

                        </div>
                        <div className="col-md-9 col-sm-12 text-center text-md-start text-sm-center">
                            <h3>Business hours</h3>
                            <p>Mon-Fri : 9 to 18</p>
                            <p>Sat: 9 to 13</p>
                            
                        </div>
                    </div>


                </div>
                <div data-aos="fade-up" data-aos-delay="400" data-aos-duration='1200' className="col-sm-12 col-md-6">
                    <form method="post" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                            <input type="text" name="fullName" className="form-control" placeholder="Full name" value={this.state.fullName} onChange={this.handleChange('fullName')} />
                            </div>
                            <div className="col-md-6 col-sm-12">
                            <input type="text" name="email" className="form-control" placeholder="Email address" value={this.state.email} onChange={this.handleChange('email')} />
                            </div>
                        </div>
                        <div className="col-12">
                            <input type="text" name="subject" className="form-control" placeholder="Subject" value={this.state.subject} onChange={this.handleChange('subject')} />
                        </div>
                        <div className="col-12">
                        <textarea name="message" className="form-control" placeholder="Project description" cols="30" rows="5"
                        value={this.state.message} 
                        onChange={this.handleChange('message')}
                        >

                        </textarea>
                        </div>
                        <div className="col-12">
                        <button className="btn btn-primary" type="submit">Send Request</button>
                        </div>
                    </form>
                    </div>

                    <div data-aos="fade-left" data-aos-delay="500" data-aos-duration='1200' className="col-md-3 col-sm-12 map">
                    <GMap/>
                    </div>
      </div>
    )

}

}