import React, { useEffect } from "react";
import Logo from '../images/Logo.png'
import { Link } from "react-router-dom";

function NavBar(){
  useEffect(() => {
    document.addEventListener("scroll", () => {
      let navbak = document.querySelector('.navbar');
      if (window.scrollY > 40) {
        navbak?.classList.add('bg-dark');
        //navbak.setAttribute("style", "background-color:rgba(0, 0, 0, 0.9)!important;"); 
        
      } else {
        navbak?.classList.remove('bg-dark');
        //navbak.setAttribute("style", "background-color:rgba(0, 0, 0, 0)!important;")
      }
    },[])});
    
return  ( <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
<div className="container-fluid px-3">
  <a className="navbar-brand" href="#"><img src={Logo} height="45" alt="I'm online logo"/></a>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" href="#">Sign up</a>
      </li>
      <li className="nav-item">
      <Link className="nav-link" to="/survey">Login</Link>
        
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#contactus">Contact us</a>
      </li>
    </ul>
  </div>
</div>
</nav> )

}

export default NavBar;