import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const Survey = () =>{
    
    
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
      

      let navigate = useNavigate();
      const InsertRecord=(e)=>{
       
    
        if ((email.length===0) || (password.length===0)){
          alert("Required Field Is Missing!!!");
        }else{
          var APIURL = "https://imonline.ca/backend/login.php";
    
          var headers = {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
          };
                
          var Data ={
            Email: email,
            Password: password
          };
    
          fetch(APIURL,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(Data)
          })
          .then((Response)=>Response.json())
          .then((Response)=>{
            alert(Response[0].Message)
            if (Response[0].Message === "Success") {
              console.log("true")
              localStorage.setItem("key", Response[0].cred)
              navigate('/loggedin');
              

             
            }
            console.log(Data);
          })
          .catch((error)=>{
            console.log(Data);
            console.error("ERROR FOUND" + error);
          })
        }
        e.preventDefault();
      }
    
     
  return (
   <>
      
        <h1 className="text-center">Welcome Talkeez guest</h1>
        <h2 className="text-center">Please login</h2>
        <div className="loginForm d-flex justify-content-center mt-3 w-25 mx-auto">
        <form className="row" onSubmit={InsertRecord}>
            
                
                <div className="col-12">
                <input className="form-control" type="text" placeholder="Username" value={email} onChange={(e)=>setEmail(e.target.value)}/></div>
                
                <div className="col-12">
                <input className="form-control mt-3" type="password" placeholder="Password" value={password} 
              onChange={(e)=>setPassword(e.target.value)}/></div>
                <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-secondary mt-4" >Login</button></div>
            
            
        </form>
      </div></>
    );
  }
  export default Survey;
  
